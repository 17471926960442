import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

/* Images */
import AppStoreBtn from "../../images/app-store.svg"
import GooglePlayBtn from "../../images/google-play.svg"

import Favicon16 from "../../../static/favicon/favicon-16x16.png"
import SafariPinnedTab from "../../../static/favicon/safari-pinned-tab.svg"

const SEO = ({ title, description, image, keywords, appId, siteName }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const { i18n } = useTranslation()
  const currentLang = i18n.language

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultKeywords,
    defaultAppId,
    defaultSiteName,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || defaultKeywords,
    appId: appId || defaultAppId,
    siteName: siteName || defaultSiteName,
  }

  const faviconLinks = [
    { rel: "icon", type: "image/png", sizes: "16x16", href: Favicon16 },
    { rel: "mask-icon", color: "#007649", href: SafariPinnedTab },
  ]

  return (
    <Helmet
      htmlAttributes={{ lang: currentLang }}
      title={seo.title}
      link={faviconLinks}
    >
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="image" content={seo.image} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:secure_url" content={seo.image} />
      <meta
        name="apple-itunes-app"
        content={`app-id=${seo.appId}, app-argument=${seo.url}`}
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="630" />
      <meta property="og:image:height" content="315" />
      <meta property="og:image:alt" content={seo.siteName} />
      <meta property="og:site_name" content={seo.siteName} />

      <meta name="msapplication-TileColor" content="#007649" />

      <link rel="preload" href={AppStoreBtn} as="image" type="image/svg+xml" />
      <link
        rel="preload"
        href={GooglePlayBtn}
        as="image"
        type="image/svg+xml"
      />
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
  appId: PropTypes.string,
  siteName: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  keywords: null,
  appId: null,
  siteName: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultKeywords: keywords
        defaultAppId: appId
        defaultSiteName: siteName
      }
    }
  }
`
